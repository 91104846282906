import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactMarkdown from 'react-markdown'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Loader, TextField, Typography } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors, REGEXP, routes } from 'constants/index'
import {
    getAnswer,
    setClientQuestion,
    setCountryStateValue,
} from '../../store/actions/search'
import { navigateTo } from '../../store/actions/navigation'
import StatesAutocomplete from '../../components/search/StatesAutocomplete'
import { useQuery } from '../../utils/hooks'

const useStyles = makeStyles({
    wrapper: {
        paddingBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        marginTop: 25,
        '@media(max-width:767px)': {
            flexWrap: 'wrap',
            padding: '0 15px 50px',
        },
    },
    stateWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
        '@media(max-width:767px)': {
            flexWrap: 'wrap',
            width: 'calc(100vw - 30px)',
        },
    },
    startPageBtn: {
        cursor: 'pointer',
        height: 57,
        background: colors.PRIMARY.COLOR[50],
        borderRadius: '0 5px 5px 0',
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.PRIMARY.COLOR[0],
        '&:hover': {
            backgroundColor: colors.PRIMARY.COLOR[60],
            color: colors.WHITE,
        },
        '@media(max-width:767px)': {
            width: 'calc(100vw - 30px)',
            marginTop: 15,
            borderRadius: 5,
        },
    },
    linkButton: {
        cursor: 'pointer',
        height: 55,
        background: colors.PRIMARY.COLOR[50],
        borderRadius: '5px',
        fontSize: 16,
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px 40px',
        color: colors.PRIMARY.COLOR[0],
        '&:hover': {
            backgroundColor: colors.PRIMARY.COLOR[60],
            color: colors.WHITE,
        },
    },
    notched: {
        border: 0,
    },
    searchRoot: {
        width: 558,
        height: 120,
        padding: 9,
        borderRadius: '4px 4px 4px 4px',
        border: `${1}px solid ${colors.BASIC.COLOR[30]}`,
        '@media(max-width:1023px) and (min-width:768px)': {
            width: 558,
        },
        '@media(max-width:768px)': {
            width: '100%',
            marginBottom: 12,
            borderRadius: 4,
        },
    },
    buttonTypeSearch: {
        top: 5,
        left: 276,
        padding: '6px 24px',
        fontSize: '10px',
        height: 20,
        minHeight: 20,
        width: 70,
        '@media(max-width:1279px)': {
            left: 174,
        },
        '@media(max-width:939px)': {
            left: 130,
        },
        '@media(max-width:768px)': {
            left: 'unset',
            right: 12,
            top: 17,
        },
    },
    clientQuestion: {
        marginTop: 20,
        padding: 24,
        background: colors.PRIMARY.COLOR[0],
        borderRadius: '16px 16px 0 16px',
        border: `${1}px solid ${colors.PRIMARY.COLOR[40]}`,
        width: 558,
        '@media(max-width:767px)': {
            width: 'calc(100vw - 30px)',
        },
    },
    authorTitle: {
        color: colors.PRIMARY.COLOR[50],
        fontWeight: 700,
        fontSize: 15,
        marginBottom: 10,
    },
    aiAnswer: {
        marginTop: 20,
        padding: 24,
        background: colors.BASIC.COLOR[0],
        borderRadius: '16px 16px 16px 0',
        border: `${1}px solid ${colors.BASIC.COLOR[10]}`,
        width: 558,
        '@media(max-width:767px)': {
            width: 'calc(100vw - 30px)',
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
        width: 558,
        '@media(max-width:767px)': {
            width: 'calc(100vw - 30px)',
        },
    },
})

const AIChatPage = (props) => {
    const { location } = props
    const query = useQuery()
    const classes = useStyles()
    const dispatch = useDispatch()
    const countryState = useSelector(state => state.search.countryStateValue)
    const question = useSelector(state => state.search.clientQuestion)
    const practiceArea = useSelector(state => state.search.practiceAreaValue)
    const answer = useSelector(state => state.search.aiAnswer)
    const answerIsLoading = useSelector(state => state.search.answerIsLoading)
    const isDisclaimerChecked = window.sessionStorage.getItem('isDisclaimerChecked')
    const state = query.get('state')
    const [searchName, setSearchName] = useState(question)
    const [currentSearchState, setCurrentSearchState] = useState(countryState)
    const [aiAnswer, setAiAnswer] = useState(answer)
    const [href, setHref] = useState(window.location.origin + routes.SEARCH_PAGE)

    useEffect(() => {
        if (isDisclaimerChecked === 'false') {
            dispatch(navigateTo(routes.START_PAGE))
        }
    }, [dispatch, isDisclaimerChecked])

    useEffect(() => {
        const params = {
            practice_area: practiceArea,
            state: currentSearchState,
        }
        let queryString = ''
        for (const key in params) {
            if (params[key]) {
                queryString += queryString ? '&' : '?'
                queryString += `${key}=${params[key]}`
            }
        }
        setHref(`${window.location.origin + routes.SEARCH_PAGE}${queryString}`)
    }, [practiceArea, currentSearchState])

    useEffect(() => {
        setCurrentSearchState(countryState)
        if (REGEXP.matchToSearchRoute.test(location.pathname)) {
            if (state) {
                dispatch(setCountryStateValue(state))
            }
        }
    }, [location.pathname, countryState, state, dispatch])

    useEffect(() => {
        if (!!question && !!countryState) {
            dispatch(getAnswer())
        }
    }, [countryState, dispatch, question])

    useEffect(() => {
        setAiAnswer(answer)
    }, [answer])

    const onChangeSearch = value => {
        setSearchName(value)
    }

    const onSubmit = () => {
        dispatch(setClientQuestion(searchName.trim()))
        dispatch(setCountryStateValue(currentSearchState))
    }

    const onLinkClick = () => {
        window.top.location.href = href
    }

    return (
      <div className={classes.wrapper}>
        <div>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="In your own words, tell me about your legal question?"
            onChange={e => onChangeSearch(e.target.value)}
            value={searchName}
            inputProps={{
                        className: classes.headerInputRoot,
                    }}
            InputProps={{
                        classes: {
                            notchedOutline: classes.notched,
                            root: classes.searchRoot,
                        },
                    }}
          />
          <div className={classes.stateWrapper}>
            <StatesAutocomplete
              isBordered
              type="startPage"
              setCurrentSearchState={setCurrentSearchState}
              currentSearchState={currentSearchState}
            />
            <Button
              variant="primary"
              onClick={onSubmit}
              className={classes.startPageBtn}
              disabled={!searchName.trim() || !currentSearchState}
            >
              Submit
            </Button>
          </div>
          {answer ? <>
            <div className={classes.clientQuestion}>
              <Typography variant="subtitle1" className={classes.authorTitle}>
                You
              </Typography>
              <Typography variant="subtitle1">
                {question}
              </Typography>
            </div>
            <div className={classes.aiAnswer}>
              <Typography variant="subtitle1" className={classes.authorTitle}>
                AI
              </Typography>
              <ReactMarkdown children={aiAnswer} />
              {practiceArea && <>
                <br />
                <span>
                  As with all legal matters, its best to consult with an attorney.
                </span>
                  {' '}
                <a href={href} style={{ color: colors.PRIMARY.COLOR[50], textDecoration: 'underline' }}>CLICK HERE</a>
                  {' '}
                <span>
                  to see a list of attorneys that specialize in the area of law related to your question.
                </span>
                               </>}
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                variant="primary"
                onClick={onLinkClick}
                className={classes.linkButton}
              >
                View list of lawyers that can help
              </Button>
            </div>
                    </>
            : null}
          {answerIsLoading && <Loader />}
        </div>
      </div>
    )
}

export default AIChatPage
