import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'components'
import { withStyles } from '@material-ui/core/styles'
import ChangePassword from './changePassword/ChangePassword'
import { updateUserPassword } from 'store/actions/auth'
import { ReactComponent as LockIcon } from 'static/profile/lock.svg'

const styles = {
  buttonText: {
    lineHeight: 1.09,
    letterSpacing: 0.3,
    padding: 0,
    fontSize: 11
  },
  icon:{
    marginRight: 4
  }
}

class SettingsContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
    }
  }

  onChangePassClick = () => {
    this.setState({
      openDialog: true,
    })
  }

  onCloseDialog = () => {
    if (this.props.isPasswordChanging) {
      return
    }
    this.setState({
      openDialog: false,
    })
  }

  onChangePassword = passwords => {
    if (this.props.isPasswordChanging) {
      return
    }
    this.props.changePassword(passwords)
  }

  render() {
    const { classes, isPasswordChanging } = this.props
    const { openDialog } = this.state
    return (
      <>
        <Button
          variant="text"
          classes={{
            // root: classes.buttonRoot,
            text: classes.buttonText,
          }}
          onClick={this.onChangePassClick}
        >
          <LockIcon className={classes.icon}/>Change MY Password
        </Button>
        <ChangePassword
          open={openDialog}
          handleClose={this.onCloseDialog}
          changePassword={this.onChangePassword}
          isPasswordChanging={isPasswordChanging}
        />
      </>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  isPasswordChanging: auth.isChangingPassword,
})

const mapDispatchToProps = dispatch => ({
  changePassword: passwords => dispatch(updateUserPassword(passwords)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SettingsContainer))
