import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon } from 'components'
import { getCalendarConnection } from 'store/actions/calendar'
import { openSnackbar } from 'store/actions/common'
import { useDispatch } from 'react-redux'
import { useGoogleLogin } from '@react-oauth/google'

const useStyles = makeStyles({
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
})

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

const GoogleCalendarConnectButton = ({ setCalendar, provider }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const makeApiCall = async ({ code }) => {
    const calendar = await dispatch(getCalendarConnection({ code, provider }))
    if (calendar) setCalendar(calendar)
  }
  const onGoogleLogin = payload => {
    // Because of Incremental authorization there is a necessity
    // to request basic permissions for google account and only
    // then send request for additional permissions, which in our case is calendar API,
    // that is why we have such flow: we send request to get permissions,
    // if user already have basic permissions - we can access calendar API and authorization code is OK,
    // if not - we catch an error, because code has only basic scope
    // and make a request once again with calendar scope
    makeApiCall(payload).catch(async e => {
      if (e.response && e.response.status === 400) {
        //this code is taken from react-google-login library,
        // the only change -  we specify scope and access type in options for request
        window.google.accounts.oauth2
          .initCodeClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'https://www.googleapis.com/auth/calendar',
            callback: makeApiCall,
            error_callback: e => {
              if (e.type === 'popup_failed_to_open')
                window.alert(
                  'Please, allow pop-up window on this site or reload the page (preferable for Safari) and connect your calendar once again'
                )
              else dispatch(openSnackbar('error', 'Error while calendar connection'))
            },
          })
          .requestCode()
      } else {
        dispatch(openSnackbar('error', 'Error while calendar connection'))
      }
    })
  }

  const login = useGoogleLogin({
    onSuccess: onGoogleLogin,
    access_type: 'offline',
    prompt: 'consent',
    flow: 'auth-code',
  })

  return (
    <Button variant="secondary" onClick={login}>
      <Icon style={classes.icon} iconClass="las la-plug" />
      connect
    </Button>
  )
}

export default GoogleCalendarConnectButton
