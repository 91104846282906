import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, LicensingRow, Link, Text, Tooltip, Typography } from 'components'
import { colors } from '../../constants'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  licenseRow: {
    marginBottom: 12,
  },
  tooltip: {
    padding: 10,
    width: 180,
  },
  icon: {
    marginLeft: 10,
    color: colors.BASIC.COLOR[70],
    fontSize: 20,
    cursor: 'pointer',
    '&:hover': {
      color: colors.PRIMARY.COLOR[60],
    },
  },
})

const TitleTooltipContent = ({ adminEmail, goToZendesk }) => (
  <div>
    <Text>
      To change your Bar/License Number or add a new one please contact <Link onClick={() => goToZendesk(false, true)}>XIRA</Link>.
    </Text>
  </div>
)

const LicensingSection = props => {
  const { states, licensing, containerClass, adminEmail, goToZendesk } = props
  const classes = useStyles()
  return (
    <div className={containerClass}>
      <div className={classes.title}>
        <Typography variant="h4">Licensing</Typography>
        <Tooltip
          title={<TitleTooltipContent goToZendesk={goToZendesk} adminEmail={adminEmail} />}
          tooltipClass={classes.tooltip}
          interactive
        >
          <Icon iconClass="las la-info-circle" style={classes.icon} />
        </Tooltip>
      </div>
      {licensing &&
        licensing.map(license => {
          return (
            <LicensingRow
              key={license.id}
              id={license.id}
              barId={license.barId}
              state={license.state}
              verificationLink={license.verificationLink}
              states={states}
              rowClass={classes.licenseRow}
              isDisabled
              isAdminLinkShown
            />
          )
        })}
    </div>
  )
}

export default LicensingSection
