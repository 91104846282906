import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { FileInput } from 'components'
import { ReactComponent as UploadIcon } from 'static/profile/file-upload__orange.svg'
import { ReactComponent as AddHoverIcon } from 'static/profile/add_icon_white.svg'
import { ReactComponent as AvatarPlaceHolder } from 'static/profile/no_avatar.svg'
import CropAvatarModal from 'containers/profileCreation/modals/CropAvatarModal'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  avatarWrapper: {
    width: 168,
    position: 'relative',
  },
  avatar: {
    backgroundColor: '#f7fafc',
    color: '#c1cbd6',
    border: `dotted 2px #c1cbd6`,
  },
  fileInput: {
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  photoIcon: {
    position: 'absolute',
    fontSize: 20,
    color: colors.WHITE,
    bottom: 0,
    left: 'calc(50% - 20px)',
    background: 'rgba(0,0,0,.5)',
    borderRadius: '50%',
    padding: 10,
  },
  hoverContainer: {
    backgroundColor: colors.BASIC.COLOR[100],
    padding: 0,
    opacity: 0.7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: '50%',
  },
  fileInput__hover: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 11,
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'flex',
  },
  fileInput__icon: {
    marginRight: 4,
  },
})

const fileSizeLimit = 5e6

const validateFile = file => {
  if (file.size >= fileSizeLimit) {
    return {
      message: 'The incorrect size of upload file',
      isValid: false,
    }
  } else if (!validateFileExtension(file.type)) {
    return {
      message: 'The incorrect type of upload file',
      isValid: false,
    }
  }
  return {
    message: '',
    isValid: true,
  }
}

const validateFileExtension = fileType => {
  const allowedExtensions = /\/(jpe?g|png|gif)$/i
  return allowedExtensions.test(fileType)
}

const ProfileAvatarEditor = props => {
  const classes = useStyles()
  const { pictureUrl, picture, avatarStyles, editable, onSave, setErrorMessage, setPicture, isSubmit, avatarWrapperClass } = props
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [hover, setHover] = useState(false)

  const onFileUploadComplete = async e => {
    const file = e.target.files[0]
    const { isValid, message } = await validateFile(file)
    if (!isValid) {
      if (setErrorMessage) {
        setErrorMessage([message], 'We have detected some problems with your uploaded portrait image')
      }
      return
    }
    setPicture(file)
    setIsCropModalOpen(true)
  }

  const onCropModalClose = () => {
    setIsCropModalOpen(false)
    setHover(false)
  }

  const onCropComplete = async file => {
    await onSave(file)
    onCropModalClose()
  }

  return (
    <div className={classNames(classes.avatarWrapper, avatarWrapperClass)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Avatar
        src={pictureUrl}
        className={classNames({
          [classes.avatar]: true,
          [avatarStyles]: true,
        })}
      >
        <AvatarPlaceHolder />
      </Avatar>

      {editable && (
        <>
          <FileInput
            id="file"
            onChange={onFileUploadComplete}
            labelClass={pictureUrl && hover ? classes.hoverContainer : classes.fileInput}
            textClasses={classes.fileInput__hover}
          >
            {pictureUrl && hover && (
              <>
                {' '}
                <UploadIcon className={classes.fileInput__icon} /> upload another photo{' '}
              </>
            )}
          </FileInput>
        </>
      )}

      <CropAvatarModal
        isOpen={isCropModalOpen}
        handleClose={onCropModalClose}
        handleImageUpload={onFileUploadComplete}
        picture={picture}
        onSave={onCropComplete}
        isSubmit={isSubmit}
      />
    </div>
  )
}

export default ProfileAvatarEditor
