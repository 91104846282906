import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { Search, Typography, SearchByAi } from 'components'
import { colors, routes } from 'constants/index'
import BackgroundImage from 'static/mainPage/landing.photo1.jpg'

const useStyles = makeStyles({
  startPage: {
    padding: '150px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    minHeight: '100vh',
    '@media(max-width:1023px)': {
      height: 'auto',
      padding: '125px 25px',
    },
    '@media(max-width:767px)': {
      height: 'auto',
      padding: '85px 15px 53px',
    },
    '@media(max-width:500px)': {
      paddingTop: 200,
    },
  },
  content: {
    zIndex: 1,
    justifyContent: 'center',
    marginBottom: 8,
  },
  mainPageTitleWrapper: {
    marginBottom: 21,
  },
  mainPageTitle: {
    fontFamily: 'Roboto',
    fontSize: 40,
    fontWeight: 'bold',
    color: colors.WHITE,
    '@media(max-width:1023px)': {
      fontSize: 33,
    },
    '@media(max-width:500px)': {
      fontSize: 22,
    },
    '@media(max-width:320px)': {
      fontSize: 19,
    },
  },
  search: {
    '@media(max-width:767px)': {
      height: 189,
    },
  },
  mainMiddleText: {
    fontSize: 20,
    color: colors.WHITE,
    marginTop: 17,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    lineHeight: 2.5,
    '@media(max-width:375px)': {
      fontSize: 16,
      lineHeight: 3.13,
    },
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
    height: '100%',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '@media(max-width:1023px) and (min-width:768px)': {},
    '@media(max-width:500px)': {
      backgroundPosition: '71%',
    },
  },
})

const PortalSearch = ({ className }) => {
  const classes = useStyles()
  const hideSearch =
    [
      routes.getUserPasswordUpdatePath(),
      routes.SIGN_IN,
      routes.SIGN_UP_ONLY,
      routes.getUserPasswordResetUnauthorizedPath(),
    ].includes(window.location.pathname) ||
    (window.location.pathname === routes.LOGIN && window.location.search.includes('redirect'))
  const role = useSelector(state => state.auth.currentUser.role)

  return (
    <div className={classnames(classes.startPage, className)}>
      {!hideSearch && (
        <div className={classes.content}>
          <div className={classes.mainPageTitleWrapper}>
            <Typography className={classes.mainPageTitle} variant="h1">
              find a lawyer now
            </Typography>
            <Typography className={classes.mainPageTitle} variant="h1">
              book a time that works for you
            </Typography>
            <Typography className={classes.mainPageTitle} variant="h1">
              meet online from wherever
            </Typography>
          </div>
          <div className={classes.search}>{role !== 'pro' ? <Search type="startPage" /> : null}</div>
          <div className={classes.search}>{role !== 'pro' ? <SearchByAi /> : null}</div>
          <Typography className={classes.mainMiddleText} variant="h1">
            find &#8226; book &#8226; meet – law made easy
          </Typography>
        </div>
      )}
      <div className={classes.videoContainer}>
        {/*<img src={BackgroundImage} alt="XIRA" className={classes.image} />*/}
      </div>
    </div>
  )
}

export default PortalSearch
