import React, { useState } from 'react'
import { Button, LinearProgress, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { useDispatch } from 'react-redux'
import { cancelVideoUpload, uploadVideo } from 'store/actions/accountProfile'
import DndVideoBox from './dndVideoBox'

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '25px 0',
    padding: 20,
    minWidth: 420,
    borderRadius: 4,
    color: colors.BASIC.COLOR[50],
    boxShadow: '0 1px 8px 0 rgba(87, 87, 87, 0.16)',
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    width: 'fit-content',
  },
  boldText: {
    fontWeight: 700,
  },
  progressBar: {
    marginTop: 5,
  },
  label: {
    color: colors.BASIC.COLOR[100],
  },
  cancelBtn: {
    color: colors.PRIMARY.COLOR[50],
  },
})

const getVideoErrorsTemplate = errorText => `please make sure you are uploading ${errorText}`
const MAX_VIDEO_SIZE_BYTES = 66000000
const SUPPORTED_VIDEO_TYPES = ['video/mp4', 'video/webm']
const CANCEL_BUTTON_TEXT = 'CANCEL'

const VideoUpload = ({ updateUploadingStatus }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [fileName, setFileName] = useState('')
  const [error, setError] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const onFileUpload = async file => {
    if (file.size > MAX_VIDEO_SIZE_BYTES) {
      setError(getVideoErrorsTemplate('a file lesser than 66 MB'))
      return
    }
    setFileName(file.name)
    setIsUploading(true)
    updateUploadingStatus(true)

    await dispatch(uploadVideo(file, onUploadProgress))

    setIsUploading(false)
    updateUploadingStatus(false)
  }

  const onUploadCancel = () => {
    cancelVideoUpload()
    setIsUploading(false)
    updateUploadingStatus(false)
    setProgress(0)
  }

  const handleFileDrop = (item, monitor) => {
    if (monitor) {
      const file = monitor.getItem().files[0]
      if (!SUPPORTED_VIDEO_TYPES.includes(file.type)) {
        setError(getVideoErrorsTemplate('a supported file type'))
      } else if (file.size > MAX_VIDEO_SIZE_BYTES) {
        setError(getVideoErrorsTemplate('a file lesser than 66 MB'))
      } else {
        onFileUpload(file)
        setError(null)
      }
    }
  }

  const onUploadProgress = uploadProgress => {
    setProgress(uploadProgress)
  }

  const mountUploadArea = () => (
      <DndVideoBox
        onDrop={handleFileDrop}
        onFileUpload={onFileUpload}
        error={error}
        supportedVideoTypes={SUPPORTED_VIDEO_TYPES.join(', ')}
      />
  )

  const mountLoader = () => (
    <div className={classes.loadingContainer}>
      <div className={classes.progress}>
        <Typography variant="body2" className={classes.label}>
          File <span className={classes.boldText}>{fileName || 'dfgfdg'}</span> is uploading ...
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          color="primary"
          classes={{ root: classes.progressBar }}
        />
      </div>
      <Button variant="text" onClick={onUploadCancel} className={classes.cancelBtn}>
        {CANCEL_BUTTON_TEXT}
      </Button>
    </div>
  )

  return isUploading ? mountLoader() : mountUploadArea()
}

export default VideoUpload
