import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { Typography, FileUploadButton, VaultDragDropArea } from 'components'
import { colors } from 'constants/index'
import { ReactComponent as EmptyLogo } from 'static/vault/folder/emptyFolder.svg'


const useStyles = makeStyles({
  folderEmptyWrapper: {
    height: '100%',
    width: '100%',
    padding: '24px 24px',
  },
  folderEmptyContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.BASIC.COLOR["5"],
    border: `2px dashed ${colors.BASIC.COLOR["20"]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hoverFolderEmptyContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.ADDITIONAL.COLOR["5"],
    border: `2px dashed ${colors.ADDITIONAL.COLOR["20"]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image:{
    marginBottom: 24
  },
  emptyStateText:{
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR["80"],
    marginBottom: 8
  },
  input: {
    display: 'none',
  },
})

const FolderVaultEmptyState = ({onAddFile, caseLabel}) => {
  const classes = useStyles()
    return <VaultDragDropArea onAddFile={onAddFile}
                              wrapperClasses={classes.folderEmptyWrapper}
                              containerClasses={classes.folderEmptyContainer}
                              hoverContainerClasses={classes.hoverFolderEmptyContainer}
                              inputID='vault_file_upload'
                              hoverText={`drop your files here to upload to the folder ${caseLabel}`}
                              inputClasses={classes.input}
                              withFileInput>
              <EmptyLogo className={classes.image}/>
              <Typography className={classes.emptyStateText}>the case folder is empty</Typography>
              <Typography className={classes.emptyStateText}><b>drag & drop</b> files here or</Typography>
              <FileUploadButton htmlFor={'vault_file_upload'} label={'UPLOAD FILES'} className={classes.uploadBtn}/>
  </VaultDragDropArea>

}

export default FolderVaultEmptyState