import { combineReducers } from 'redux'
import auth from './auth'
import search from './search'
import { profileCreation } from './profileCreation'
import accountProfile from './accountProfile'
import common from './common'
import calendar from './calendar'
import meeting from './meeting'
import payments from './payments'
import events from './events'
import meetingScheduler from './meetingScheduler'
import cases from './cases'
import connections from './connections'
import activities from './activities'
import invoices from './invoices'
import wallet from './wallet/wallet'
import chat from './chat/chat'
import vault from './vault'
import notifications from './notifications/notifications'
import proReview from "./proReview/proReview"

const reducers = combineReducers({
  auth,
  profileCreation,
  accountProfile,
  common,
  search,
  calendar,
  meeting,
  payments,
  events,
  meetingScheduler,
  connections,
  cases,
  activities,
  invoices,
  wallet,
  chat,
  vault,
  notifications,
  proReview
})

export default reducers
