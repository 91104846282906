import React, {useEffect} from "react"
import {makeStyles} from "@material-ui/core"
import classNames from 'classnames'
import {useDispatch, useSelector} from "react-redux"
import { CONSTANTS, colors } from 'constants/index'
import { FolderVaultTable, FolderVaultEmptyState, VaultFolderInputArea, MoveProgressModal, VaultFileUploadProgressModal, VaultDragDropArea} from 'components'
import { toArray } from 'lodash'
import {
  setUploadFile,
  setVaultItemsAction,
  setVaultUploadItemsModalOpen
} from "store/actions/vault"
import { ReactComponent as UploadIcon } from 'static/vault/cloud_upload_orange.svg'
import {getIsDisableSelection} from "store/selectors/vaultSelector"

const useStyles = makeStyles({
    folderTableContainer: {
      paddingBottom: 70,
    },
    vaultFolderContainer: {
      position: 'relative',
      width: '100%',
    },
    folderEmptyWrapper: {
      position: 'absolute',
      top: 24,
      height: '88%',
      width: '100%',
      padding: '0 24px 24px 24px',
      transition: '0.2s linear',
    },
    hoverfolderEmptyWrapper: {
      height: '100%',
      top: 0,
      padding: 0,
      zIndex: 1,
    },
    folderEmptyContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hoverFolderEmptyContainer: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.ADDITIONAL.COLOR["5"],
      border: `2px dashed ${colors.ADDITIONAL.COLOR["40"]}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

const FileUpload = ({onAddFile, isEmptyList, caseLabel}) => {
  return isEmptyList
    ? <FolderVaultEmptyState onAddFile={onAddFile} caseLabel={caseLabel}/>
    : <VaultFolderInputArea onAddFile={onAddFile} caseLabel={caseLabel}/>
}


const VaultFolder = ({ selectedFolders, selectedItems, filesList, itemsAction, handleMove, ...props}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isUploadModalOpen = useSelector(state => state.vault.isUploadModalOpen)
  const fileProgress = useSelector(state => state.vault.fileProgress)
  const filesAreUploading = useSelector(state => state.vault.filesAreUploading)
  const disableSelection = useSelector(getIsDisableSelection)
  const uploadingItems = toArray(fileProgress)
    .filter(file => file.status === CONSTANTS.FILE_UPLOAD_STATUS.IN_PROGRESS)

  useEffect(() => {
    if(!uploadingItems.length && itemsAction === CONSTANTS.VAULT_ACTIONS.UPLOAD)
      dispatch(setVaultItemsAction(null))
  }, [uploadingItems])

  useEffect(() => {
    if(!filesAreUploading) closeUploadItemsModal()
  }, [filesAreUploading])

  const onAddFile = files => {
    dispatch(setUploadFile(toArray(files)))
  }

  const closeUploadItemsModal = () => dispatch(setVaultUploadItemsModalOpen(false))

  const caseLabel = selectedFolders && selectedFolders[selectedFolders.length - 1] && selectedFolders[selectedFolders.length - 1].name

  return <div className={classNames(classes.vaultFolderContainer, {[classes.folderTableContainer]: !!filesList.length})}>
    {!!filesList.length &&
      <VaultDragDropArea
        onAddFile={onAddFile}
        wrapperClasses={classes.folderEmptyWrapper}
        hoverWrapperClasses={classes.hoverfolderEmptyWrapper}
        containerClasses={classes.folderEmptyContainer}
        hoverContainerClasses={classes.hoverFolderEmptyContainer}
        inputID="vault_file_upload"
        hoverText={`drop your files here to upload to the folder ${caseLabel}`}
        inputClasses={classes.input}
      >
        <FolderVaultTable
          data={filesList}
          disableSelection={disableSelection}
          selectedFolders={selectedFolders}
          selectedItems={selectedItems}
          handleMove={handleMove}
          {...props}
        />
      </VaultDragDropArea>
            }
            <FileUpload onAddFile={onAddFile}
                        isEmptyList={!filesList.length}
                        caseLabel={caseLabel}
            />
    <MoveProgressModal isOpen={itemsAction === CONSTANTS.VAULT_ACTIONS.UPLOAD && !isUploadModalOpen}
                       icon={<UploadIcon/>}
                       message={`uploading ${uploadingItems.length} ${uploadingItems.length > 1 ? 'items' : 'item'}...`}
    />
    <VaultFileUploadProgressModal fileProgress={fileProgress}
                                  onClose={closeUploadItemsModal}
                                  isOpen={isUploadModalOpen}
    />
      </div>
}

export default VaultFolder