import React from "react"
import {useDispatch, useSelector} from "react-redux"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { VaultTable, VaultCasesTableRow} from 'components'
import {setVaultSelectedFolders, setVaultCase} from "store/actions/vault"
import { colors } from 'constants/index'


export const useStyles = makeStyles({
  tableRow:{
    '&.MuiTableRow-hover:hover':{
      backgroundColor: colors.ADDITIONAL.TRANSPARENT["8"],
      color: colors.ADDITIONAL.COLOR["50"],
      '& a':{
        color: colors.ADDITIONAL.COLOR["50"],
      }
    },
  },
  tableCellClass: {
    paddingLeft: 0,
  },
  tableWrapper: {
    width: '100%',
    padding: '24px 24px',
  },
})

const columns = [
  {
    value: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    value: 'size',
    label: 'Size',
    sortable: false,
  },
  {
    value: 'items',
    label: 'Items',
    sortable: false,
  },
  {
    value: 'last_uploaded',
    label: 'Last Uploaded',
    sortable: true,
  },
  {
    value: 'buttons',
    label: '',
    sortable: false,
  },
]

const CasesVaultTable = ({filesList, selectedFolders}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  const navigateToCaseFolderDetails = (e, data) => {
    dispatch(setVaultSelectedFolders([...selectedFolders, data]))
    dispatch(setVaultCase(data.relatedID))
  }

  return (
    <div className={classes.tableWrapper}>
      <VaultTable
        onRowClick={navigateToCaseFolderDetails}
        columns={columns}
        data={filesList}
        tableRowClasses={classes.tableRow}
        tableRow={<VaultCasesTableRow timezone={timezone}/>}
        tableHeaderCellClass={{root: classes.tableCellClass}}
        activeRows
      />
    </div>
  )
}

export default CasesVaultTable