import React from 'react'
import Rating from '@material-ui/lab/Rating'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  ratingContainer: {
    display: 'flex',
    marginTop: 19,
  },
  text: {
    margin: '0 5px',
  },
})

const XRating = ({
  value,
  name,
  onChange,
  readOnly,
  ratingClasses,
  text,
  ratingContainerClasses,
  ratingContainerText,
  size = 'medium',
  precision = 1, onChangeActive = () => {}
}) => {
  const classes = useStyles()
  return (
    <div className={ratingContainerClasses || classes.ratingContainer}>
      <Rating
        size={size}
        name={name}
        value={value}
        onChange={onChange}
        onChangeActive={onChangeActive}
        readOnly={readOnly}
        classes={ratingClasses}
        precision={precision}
      />
      {text && <Typography classes={ratingContainerText ? { body1: ratingContainerText } : { body1: classes.text }}
                   variant="body1">
                    {text}
      </Typography>
      }
    </div>
  )
}

export default XRating
