import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { VaultTable, VaultTrashTableRow, ActionRow } from 'components'
import {
  setVaultSelectedItems,
  restoreTrashVaultItem,
  setVaultItemsAction,
  setVaultSingleActionItem,
} from 'store/actions/vault'
import { ReactComponent as TrashDeleteIcon } from 'static/vault/trash/actions/delete.svg'
import { ReactComponent as TrashRestoreIcon } from 'static/vault/trash/actions/restore.svg'

const useStyles = makeStyles({
  logo: {
    marginRight: 8,
  },
})

const columns = [
  {
    value: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    value: 'deleted',
    label: 'Deleted',
    sortable: true,
  },
]

const VaultTrashTable = ({ data, selectedItems }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const isRestoring = useSelector(state => state.vault.isRestoring)

  const handleSelectAll = (e, data) => {
    if(isRestoring) return
    if (e.target.checked) {
      dispatch(setVaultSelectedItems(data))
      return
    }
    dispatch(setVaultSelectedItems([]))
  }

  const handleBulkSelection = selection => {
    if (!selectedItems.length) return
    const startIndex = data.findIndex(item => item.uuid === selectedItems[selectedItems.length - 1].uuid)
    const endIndex = data.findIndex(row => row.uuid === selection.uuid)
    const filteredSelection =
      startIndex < endIndex
        ? data.slice(startIndex, endIndex + 1)
        : data.slice(endIndex, startIndex + 1).filter(select => !selectedItems.find(row => row.uuid === select.uuid))
    dispatch(setVaultSelectedItems([...selectedItems, ...filteredSelection]))
  }

  const handleTrashAction = async (action, data) => {
    if (action === 'restore') {
      await dispatch(restoreTrashVaultItem([data]))
    } else if (action === 'delete_trash') {
      dispatch(setVaultSingleActionItem([data]))
      dispatch(setVaultItemsAction(action))
    }
  }

  const getRowActions = rowData => {
    if (selectedItems.indexOf(rowData.name) !== -1) return []
    return [
      {
        key: 'restore',
        label: <ActionRow classes={classes} title={'restore'} logo={<TrashRestoreIcon className={classes.logo} />} />,
        action: data => handleTrashAction('restore', data),
      },
      {
        key: 'delete',
        label: <ActionRow classes={classes} title={'delete'} logo={<TrashDeleteIcon className={classes.logo} />} />,
        action: data => handleTrashAction('delete_trash', data),
      },
    ]
  }

  const handleRowClick = (e, rowData) => {
    if(isRestoring) return
    if (e.shiftKey) {
      handleBulkSelection(rowData)
    } else {
      const isSelected = selectedItems.find(row => row.uuid === rowData.uuid)
      handleCheckboxClick(!isSelected, rowData)
    }
  }

  const handleCheckboxClick = (selected, data) => {
    if(isRestoring) return
    if (selected) {
      if (!selectedItems.find(row => row.uuid === data.uuid)) dispatch(setVaultSelectedItems([...selectedItems, data]))
    } else dispatch(setVaultSelectedItems(selectedItems.filter(row => row.uuid !== data.uuid)))
  }

  return (
    <VaultTable
      columns={columns}
      selectable
      trash
      selectedRows={selectedItems}
      onSelectAllClick={handleSelectAll}
      data={data}
      getRowActions={getRowActions}
      onRowClick={handleRowClick}
      disableSelection={isRestoring}
      tableRow={<VaultTrashTableRow selectedRows={selectedItems} timezone={timezone} disableSelection={isRestoring}/>}
    />
  )
}

export default VaultTrashTable
